<template>
  <!-- eslint-disable -->
  <div class="w-100">
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <add-edit
        :is-add.sync="isAdd"
        ref="provAdd"
      >
      </add-edit>
      <!-- <filters
        :filtros="fields"
        :is-column.sync="isColumn"
        ref="filters"
      >
      </filters> -->
      <filter-columns
        :filtros="fields"
        :is-add.sync="isColumn"
        ref="filters"
        @update:filtros="value => (fields = value)"
      />
      <b-card
        ref="filterContent"
        no-body
        class="sticky"
      >
        <b-card-body>
          <b-row>
            <b-col
              lg="12"
              class="d-flex flex-column flex-lg-row justify-content-end"
            >
              <div class="w-100 mb-1 mb-lg-0">
                <b-form-group
                  label="Proyecto"
                  label-for="project"
                  class="mr-2"
                >
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="proyectos"
                    label="code"
                    input-id="project"
                    :reduce="proyectos => proyectos.id"
                    placeholder="Proyecto"
                    class="select-obra"
                    v-model="project_id"
                    @input="selectProyect(project_id)"
                    disabled
                  >
                    <template v-slot:selected-option="option"> {{ option.code }} - {{ option.description }} </template>
                    <template
                      slot="option"
                      slot-scope="option"
                    >
                      {{ option.code }} - {{ option.description }}
                    </template>
                  </v-select>
                </b-form-group>
              </div>
              <div class="w-100">
                <b-form-group
                  label="Descripción"
                  label-for="description"
                  class="mr-2"
                >
                  <b-form-input
                    type="text"
                    label="name"
                    id="description"
                    placeholder="Descripción"
                    v-model="description"
                    @input="filter()"
                    class="select-obra"
                    autocomplete="off"
                  >
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="w-50">
                <b-form-group
                  label="Condicion"
                  label-for="status"
                  class="mr-2"
                >
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="estadosFilter"
                    label="text"
                    input-id="status"
                    :reduce="estadosFilter => estadosFilter.value"
                    placeholder="Condicion"
                    v-model="statusFilter"
                    @input="filter()"
                    class="select-obra"
                  >
                  </v-select>
                </b-form-group>
              </div>
              <div class="w-100 d-flex align-items-center h-100 justify-content-center">
                <b-button
                  class="mr-2"
                  variant="primary"
                  @click="addRestric(project_id)"
                >
                  <span class="text-nowrap"> <feather-icon icon="PlusCircleIcon" /> Agregar </span>
                </b-button>
                <b-button
                  @click="showFilters"
                  variant="primary"
                  class="mr-2"
                >
                  Columnas
                  <feather-icon
                    v-if="show"
                    icon="PlusCircleIcon"
                    size="15"
                  />
                  <feather-icon
                    v-else
                    icon="MinusCircleIcon"
                    size="15"
                  />
                </b-button>
                <b-button
                  class="mr-2"
                  variant="warning"
                  @click="importData"
                >
                  <span class="text-nowrap"> <feather-icon icon="DownloadIcon" /> Importar </span>
                </b-button>
                <!--<b-button variant="success" class="mr-2 mb-1 mb-md-0">
                  <span class="text-nowrap"> <feather-icon icon="UploadIcon" /> Exportar </span>
                </b-button> -->
              </div>
            </b-col>
            <!-- <b-col md="6">
              
            </b-col> -->
            <!-- <b-col lg="2">
              <div
                class="d-flex align-items-center h-100 justify-content-center justify-content-lg-end"
              >
                
              </div>
            </b-col> -->
          </b-row>
        </b-card-body>
      </b-card>
      <b-card
        no-body
        ref="tableCard"
      >
        <div class="table-overflow">
          <b-table
            class="position-relative"
            empty-text="No existen"
            :fields="visibleFields"
            :hover="true"
            id="__BVID__188"
            :items="records"
            no-border-collapse
            ref="selectableTable"
            :responsive="false"
            show-empty
            @sort-changed="sortChanged"
          >
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="column-actions">
                <b-button
                  size="sm"
                  class=""
                  @click.prevent="showTimeLine(data.item)"
                  v-b-tooltip.noninteractive.hover.left="'Comentarios'"
                  variant="flat-info"
                >
                  <feather-icon icon="MessageCircleIcon" />
                </b-button>
                <b-button
                  size="sm"
                  class=""
                  @click.prevent="edit(data.item)"
                  v-b-tooltip.noninteractive.hover.left="'Editar'"
                  variant="flat-success"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
                <b-button
                  v-if="user_role != 'user'"
                  size="sm"
                  class=""
                  v-b-tooltip.noninteractive.hover.left="'Oficializar'"
                  @click="completarRestric(data.item)"
                  variant="flat-warning"
                >
                  <feather-icon icon="FolderIcon" />
                </b-button>
                <b-button
                  size="sm"
                  class=""
                  v-b-tooltip.noninteractive.hover.left="'Archivos'"
                  @click="openModal(data.item.id)"
                  variant="flat-success"
                >
                  <feather-icon icon="FileIcon" />
                </b-button>
                <b-button
                  size="sm"
                  class=""
                  v-b-tooltip.noninteractive.hover.left="'Rechazar'"
                  @click="rejectAction(data.item)"
                  v-if="data.item.status != 'Rechazado'"
                  variant="flat-danger"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
            </template>
            <template #cell(description)="data">
              <div class="description-class">
                <span>{{ data.item.description }}</span>
              </div>
            </template>
            <template #cell(reason_reject)="data">
              <div style="color: red">
                <span style="font-weight: bold">{{ data.item.reason_reject }}</span>
              </div>
            </template>
            <template #cell(category.code)="data">
              <div class="description-class">
                <span>{{ data.item.category.code }} - {{ data.item.category.description }}</span>
              </div>
            </template>
            <template #cell(location_1.code)="data">
              <div  class="description-class">
                <span>{{ data.item.location_1 ? data.item.location_1.description : ''  }}</span>
              </div>
            </template>
            <template #cell(location_2.code)="data">
              <div  class="description-class">
                <span>{{ data.item.location_2 ? data.item.location_2.description : ''  }}</span>
              </div>
            </template>
            <template #cell(location_3.code)="data">
              <div  class="description-class">
                <span>{{ data.item.location_3 ? data.item.location_3.description : ''  }}</span>
              </div>
            </template>
            <template #cell(location_4.code)="data">
              <div  class="description-class">
                <span>{{ data.item.location_4 ? data.item.location_4.description : ''  }}</span>
              </div>
            </template>
            <template #cell(date_identified)="data">
              <div class="date-class">
                <span>{{ validDate(data.item.date_identified) }}</span>
              </div>
            </template>
            <template #cell(date_required)="data">
              <div class="date-class">
                <span>{{ validDate(data.item.date_required) }}</span>
              </div>
            </template>
            <template #cell(category)="data">
              <span>{{
                data.item.category ? data.item.category.code + ' - ' + data.item.category.description : ''
              }}</span>
            </template>
          </b-table>
        </div>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col sm="3">
              <b-form-group
                label-cols="4"
                label-cols-md="4"
                label-size="md"
                label="Entradas"
                label-for="input-md"
              >
                <b-form-select
                  v-model="showEntrie"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="entries"
                  @change="changeSizePage"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="3"
              class="mt-75"
              style="font-size: 12px"
            >
              <span> {{ totalElements }} Registros en total</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalElements"
                :per-page="showEntrie"
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="cambioPagina"
                pills
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="15"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="15"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-modal
        id="modal-comment"
        ref="modal-comment"
        centered
        title="Comentarios"
        ok-only
        hide-footer
        :size="addComent && timeLine.length > 0 ? 'lg' : ''"
        @hide="closeComment"
      >
        <b-card-text class="card_content pb-2">
          <b-row>
            <b-col :md="timeLine.length > 0 ? '12' : '4'">
              <span>{{ title }}</span>
            </b-col>
            <b-col
              md="12"
              :class="timeLine.length > 0 ? 'text-right' : 'text-center'"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="!addComent ? 'danger' : 'warning'"
                @click="addComent = !addComent"
              >
                <span v-if="!addComent">Agregar</span>
                <span v-else>Ocultar</span>
              </b-button>
            </b-col>
          </b-row>
          <hr v-if="timeLine.length > 0" />
          <div class="content_main">
            <b-row :class="{ content_form: timeLine.length == 0 }">
              <b-col
                :md="addComent && timeLine.length > 0 ? '6' : '12'"
                :class="{ content_timeline: timeLine.length > 0 }"
              >
                <app-timeline>
                  <app-timeline-item
                    v-for="(item, index) in timeLine"
                    :key="index"
                    variant="primary"
                  >
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                      <h6>
                        {{ item.user_id != null ? item.user.name : 'sistema de restricciones' }}
                      </h6>
                      <small class="text-muted">{{ moment(item.created_at).format('yyyy-MM-DD') }}</small>
                    </div>
                    <p>{{ item.description }}</p>
                    <!-- <p>{{ item.description }}</p> -->
                    <!-- <p v-if="item.file_path != ''">
                      <b-img
                        :src="require('@/assets/images/pdf.png')"
                        height="auto"
                        width="20"
                        class="mr-1"
                      />
                      <a :href="item.file_path" target="_blank"
                        ><span class="align-bottom">Descargar</span></a
                      >
                    </p> -->
                  </app-timeline-item>
                </app-timeline>
              </b-col>
              <b-col
                :md="timeLine.length > 0 ? '6' : '12'"
                v-if="addComent"
              >
                <validation-observer ref="addComentarios">
                  <b-form
                    :class="{ section_add: timeLine.length > 0 }"
                    @submit.prevent="addComment"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          label="Comentario"
                          label-for="comentario"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="comment"
                          >
                            <b-form-textarea
                              placeholder="Comentario"
                              id="comentario"
                              rows="3"
                              no-resize
                              v-model="comment"
                            />
                            <small
                              v-if="errors.length > 0"
                              class="text-danger"
                              >El campo es requerido</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      type="submit"
                      >Agregar</b-button
                    >
                  </b-form>
                </validation-observer>
              </b-col>
            </b-row>
          </div>
        </b-card-text>
      </b-modal>
      <b-modal
        size="xl"
        v-model="modalOpen"
        @close="closeModal"
        hide-footer
      >
        <template v-slot:default>
          <archivos
            :edit="true"
            :itemId="itemId"
            ref="archivos"
          />
        </template>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
  /* eslint-disable */
  import FilterColumns from '@/components/FilterColumns.vue'
  import ProjectsService from '@/services/ProjectsService'
  import RestrictionsService from '@/services/RestrictionsService'
  import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
  import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
  import { required } from '@validations'
  import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
  import moment from 'moment'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Vue from 'vue'
  import flatPickr from 'vue-flatpickr-component'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import addEdit from './add-edit.vue'
  import filters from './filters.vue'
  import archivos from './steps/archivos.vue'

  Vue.use(BootstrapVue)
  Vue.use(BootstrapVueIcons)
  export default {
    directives: {
      'b-tooltip': VBTooltip,
      Ripple,
    },
    data() {
      return {
        required,
        showLoading: false,
        show: true,
        timeLine: [],
        title: '',
        comment: '',
        addComent: false,
        statusFilter: 'PendientePorOficializar',
        estadosFilter: [
          {
            text: 'Pendiente por Oficializar',
            value: 'PendientePorOficializar',
          },
          {
            text: 'Rechazado',
            value: 'Rechazado',
          },
        ],
        // fields: [
        //   {
        //     key: 'code',
        //     label: 'Id-Restricción',
        //     sortable: false,
        //     visible: false,
        //     thStyle: { width: '148px' },
        //   },
        //   {
        //     key: 'description',
        //     label: 'Descripción',
        //     sortable: true,
        //     visible: true,
        //     thStyle: { width: '500px' },
        //   },
        //   { key: 'category', label: 'Categoria', sortable: true, visible: true, thStyle: { width: '150px' } },
        //   {
        //     key: 'sub_category.code',
        //     label: 'Sub-Categoria',
        //     sortable: false,
        //     visible: false,
        //     thStyle: { width: '150px' },
        //   },
        //   { key: 'priority', label: 'Prioridad', sortable: true, visible: true, thStyle: { width: '150px' } },
        //   {
        //     key: 'status_similar',
        //     label: 'Estado',
        //     sortable: true,
        //     visible: true,
        //     thStyle: { width: '150px' },
        //   },
        //   { key: 'status', label: 'Condicion', sortable: true, visible: true, thStyle: { width: '140px' } },
        //   { key: 'type', label: 'Tipo', sortable: true, visible: true, thStyle: { width: '140px' } },
        //   { key: 'user.name', label: 'Usuario Reg', sortable: true, visible: true, thStyle: { width: '160px' } },
        //   { key: 'reason_reject', label: 'Motivo Rechazo', sortable: true, visible: true, thStyle: { width: '150px' } },
        //   { key: 'owner.name', label: 'Dueño', sortable: false, visible: false, thStyle: { width: '150px' } },
        //   {
        //     key: 'co_owner.name',
        //     label: 'Co-Dueño',
        //     sortable: false,
        //     visible: false,
        //     thStyle: { width: '150px' },
        //   },
        //   { key: 'detail', label: 'Detalle', sortable: false, visible: false, thStyle: { width: '125px' } },
        //   {
        //     key: 'dependency',
        //     label: 'Dependencia',
        //     sortable: false,
        //     visible: true,
        //     thStyle: { width: '155px' },
        //   },
        //   { key: 'leadtime', label: 'LeadTime', sortable: false, visible: false, thStyle: { width: '125px' } },
        //   {
        //     key: 'date_identified',
        //     label: 'Fecha Identificada',
        //     sortable: true,
        //     visible: true,
        //     thStyle: { width: '155px' },
        //   },
        //   {
        //     key: 'date_required',
        //     label: 'Fecha Requerida',
        //     sortable: true,
        //     visible: true,
        //     thStyle: { width: '155px' },
        //   },
        //   {
        //     key: 'project.code',
        //     label: 'Proyecto',
        //     sortable: false,
        //     visible: false,
        //     thStyle: { width: '130px' },
        //   },
        //   {
        //     key: 'activity.code',
        //     label: 'Actividad',
        //     sortable: false,
        //     visible: false,
        //     thStyle: { width: '130px' },
        //   },
        //   {
        //     key: 'speciality.description',
        //     label: 'Especialidad',
        //     sortable: true,
        //     visible: true,
        //     thStyle: { width: '155px' },
        //   },
        //   {
        //     key: 'location_1.code',
        //     label: 'Localizacion 1',
        //     sortable: false,
        //     visible: false,
        //     thStyle: { width: '150px' },
        //   },
        //   {
        //     key: 'location_2.code',
        //     label: 'Localizacion 2',
        //     sortable: false,
        //     visible: false,
        //     thStyle: { width: '150px' },
        //   },
        //   {
        //     key: 'location_3.code',
        //     label: 'Localizacion 3',
        //     sortable: false,
        //     visible: false,
        //     thStyle: { width: '150px' },
        //   },
        //   {
        //     key: 'location_4.code',
        //     label: 'Localizacion 4',
        //     sortable: false,
        //     visible: false,
        //     thStyle: { width: '150px' },
        //   },
        //   { key: 'actions', label: 'Acciones', visible: true, thStyle: { width: '300px' } },
        // ],
        fields: [],
        date_start: new Date(),
        date_end: new Date(),
        isAdd: false,
        isColumn: false,
        project_id: JSON.parse(localStorage.getItem('project_id')),
        restricciones: [],
        restriction_id: null,
        records: [],
        proyectos: [],
        documentSelect: [],
        arrayFilters: [
          {
            keyContains: 'status',
            key: 'equals',
            value: 'PendientePorOficializar',
          },
        ],
        currentPage: 1,
        entries: [10, 20, 50, 100],
        showEntrie: 10,
        totalElements: 0,
        description: '',
        id: 0,
        sort: 'id',
        order: 'desc',
        isAdd: false,
        form: {
          code: '',
          description: '',
          priority: '',
          category_id: '',
          date_identified: null,
          date_required: null,
          project_id: null,
          type: '',
          leadtime: null,
        },
        userData: JSON.parse(localStorage.getItem('userData')),
        user_role: JSON.parse(localStorage.getItem('userData')).role_user[0].role_restriction,
        modalOpen: false,
        itemId: null,
        allData: [],
        allDataSorted: [],
        navbar: null,
        filterContent: null,
        tableCard: null,
        tableContainer: null,
        selectableTable: null,
        tableHead: null,
        ths: null,
        trs: null,
      }
    },
    components: {
      addEdit,
      vSelect,
      filters,
      flatPickr,
      archivos,
      AppCollapse,
      AppCollapseItem,
      AppTimeline,
      ValidationProvider,
      ValidationObserver,
      AppTimelineItem,
      FilterColumns,
    },
    computed: {
      visibleFields() {
        return this.fields.filter(field => field.visible)
      },
    },
    created() {
      // Escucha un evento personalizado llamado 'executeGetData'
      this.$bus.on('executeGetDataProvisionales', () => {
        // Llama a la función getData pasando las variables
        console.log('ejecutando prov')
        this.filter()
      })
    },
    async mounted() {
      // this.getData()
      const projectId = localStorage.getItem('project_id')
      const userId = JSON.parse(localStorage.getItem('userData')).id

      await this.fetchFilters()

      this.selectProyect(this.project_id)
      this.getSelect()
      await this.filter()

      this.navbar = document.querySelector('.navbar')
      this.filterContent = this.$refs.filterContent
      this.tableContainer = this.$el.querySelector('.table-overflow')
      this.tableCard = this.$refs.tableCard
      this.selectableTable = this.$refs.selectableTable.$el
      this.tableHead = this.selectableTable.querySelector('thead')
      this.ths = this.selectableTable.querySelector('thead').querySelectorAll('th')

      this.setupScrollSync()
      new ResizeObserver(this.fixedElements).observe(this.tableCard)
    },
    watch: {
      records(newVal, oldVal) {
        this.$nextTick(() => {
          this.trs = this.selectableTable.querySelector('tbody').querySelectorAll('tr')
          this.fixedElements()
        })
      },
      visibleFields(newVal, oldVal) {
        this.$nextTick(() => {
          this.ths = this.selectableTable.querySelector('thead').querySelectorAll('th')
          this.fixedElements()
        })
      },
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleWindowScroll)
      window.removeEventListener('resize', this.fixedElements)
    },
    methods: {
      async fetchFilters() {
        const payload = {
          viewName: 'provisionales',
          store: this.$store,
        }
        await this.$store.dispatch('filters/fetchFilters', payload)
        this.fields = this.$store.state.filters.filters
      },
      fixedElements() {
        if (!this.trs[0].classList.contains('b-table-empty-row')) {
          const thsTotalWidth = [...this.ths].reduce((acc, th) => acc + th.offsetWidth, 0)

          if (thsTotalWidth > this.tableCard.offsetWidth) {
            this.ths.forEach((th, index) => {
              th.style.flex = '0 0 ' + th.offsetWidth + 'px'
            })
          } else {
            this.ths.forEach((th, index) => {
              th.style.flex = '1 1 ' + th.offsetWidth + 'px'
            })
          }

          this.trs.forEach((tr, index) => {
            const tds = tr.querySelectorAll('td')

            this.ths.forEach((th, index) => {
              tds[index].style.width = th.offsetWidth + 'px'

              if (thsTotalWidth > this.tableCard.offsetWidth) {
                tds[index].style.flex = '0 0 ' + th.offsetWidth + 'px'
              } else {
                tds[index].style.flex = '1 1 ' + th.offsetWidth + 'px'
              }
            })
          })
        } else {
          this.selectableTable.style.width = this.tableHead.querySelector('tr').offsetWidth + 1 + 'px'
        }

        this.tableHead.style.width = this.tableCard.offsetWidth - 1 + 'px'
        this.selectableTable.style.paddingTop = this.tableHead.offsetHeight + 'px'
      },
      setupScrollSync() {
        this.tableHead.addEventListener('scroll', () => {
          this.tableContainer.scrollLeft = this.tableHead.scrollLeft
          this.tableHead.style.transform = `translateX(${this.tableHead.scrollLeft}px)`
        })

        this.tableContainer.addEventListener('scroll', () => {
          this.tableHead.scrollLeft = this.tableContainer.scrollLeft
          this.tableHead.style.transform = `translateX(${this.tableHead.scrollLeft}px)`
        })

        window.addEventListener('scroll', this.handleWindowScroll)
        window.addEventListener('resize', this.fixedElements)
      },
      handleWindowScroll() {
        this.filterContent.style.top = this.navbar.offsetHeight + 'px'

        if (this.tableCard.getBoundingClientRect().top - this.navbar.offsetHeight - 28 - window.scrollY <= 0) {
          this.tableHead.classList.add('fixed')
          this.tableHead.style.top = this.navbar.offsetHeight + this.filterContent.offsetHeight + 'px'
        } else {
          this.tableHead.classList.remove('fixed')
          this.tableHead.style.top = null
        }
      },
      filter() {
        this.arrayFilters = []
        if (this.project_id != null && this.project_id != '') {
          this.arrayFilters.push({
            keyContains: 'project_id',
            key: 'equals',
            value: this.project_id,
          })
        }
        if (this.statusFilter != null && this.statusFilter != '') {
          this.arrayFilters.push({
            keyContains: 'status',
            key: 'equals',
            value: this.statusFilter,
          })
        }
        if (this.user_role == 'user') {
          this.arrayFilters.push({
            keyContains: 'user_id',
            key: 'equals',
            value: this.userData.id,
          })
        }
        console.log('arrayFilters', this.arrayFilters)
        this.getAllData()
      },
      openModal(itemId) {
        this.$route.params.id = itemId
        console.log('item seleccionado', itemId)
        this.itemId = itemId
        this.modalOpen = true

        console.log(this.$route.params.id, 'this.$route.params.id en modal')
      },
      closeModal() {
        this.modalOpen = false
      },
      closeComment() {
        this.comment = ''
        this.addComent = false
      },
      validDate(fecha) {
        if (fecha != null) {
          const year = new Date(fecha).getFullYear()
          if (year <= 1970) {
            return ''
          }

          return moment(fecha, 'yyyy-MM-DD HH:mm').utc(fecha).format('yyyy-MM-DD')
        }
        return ''
      },
      selectProyect(item) {
        if (item != null) {
          this.project_id = item
        }
      },
      showFilters() {
        this.isColumn = true
      },
      completarRestric(item) {
        console.log('item', item)
        this.$router.push('/provisionales/completar/' + item.id)
      },
      importData() {
        this.$router.push('/provisionales/importar-datos')
      },
      cambioPagina(e) {
        this.currentPage = e
        this.records = this.allDataSorted[e - 1]
        /* this.getAllData(); */
      },
      changeSizePage() {
        this.getAllData()
      },
      sortChanged(data) {
        this.sort = data.sortBy
        this.currentPage = 1
        this.getSortedData(data.sortBy, data.sortDesc ? 'desc' : 'asc')
        this.records = this.allDataSorted[0]
        /* this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc' */
      },
      addRestric(id) {
        if (id != null) {
          // this.$router.push('/provicionales/registro/' + id)
          this.isAdd = true
          this.$refs.provAdd.setData({})
          this.$refs.provAdd.getData(this.project_id)
        } else {
          this.$swal({
            icon: 'warning',
            title: 'Proyecto',
            text: 'Seleccione un proyecto para empezar.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      },
      async getSelect() {
        const url2 = `?limit=100&page=${this.currentPage}&order=${this.order}&sort=${this.sort}`
        const respProyectos = await ProjectsService.getProyectos(url2, this.$store)
        console.log('respProyectos', respProyectos)
        if (respProyectos.status) {
          this.proyectos = respProyectos.data.rows
          // if (respProyectos.data.rows.length > 0) {
          //   this.project_id = respProyectos.data.rows[0].id
          // } else {
          //   if (user_role == 'planner') {
          //     this.project_id = 0
          //   }
          // }
          // this.selectProyect(this.project_id)
        }
      },
      async getData() {
        if (this.user_role == 'user') {
          this.arrayFilters.push({
            keyContains: 'user_id',
            key: 'equals',
            value: this.userData.id,
          })
        }
        this.showLoading = true
        const url =
          `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=` +
          JSON.stringify(this.arrayFilters)
        console.log('url', url)
        const resp = await RestrictionsService.getRestriccionesProv(url, this.$store)
        console.log('resp', resp)
        if (resp.status) {
          console.log('resp.data.rows', resp.data.rows)
          this.records = resp.data.rows
          this.totalElements = resp.data.responseFilter.total_rows
        }
        this.showLoading = false
      },
      async getAllData() {
        if (this.user_role == 'user') {
          this.arrayFilters.push({
            keyContains: 'user_id',
            key: 'equals',
            value: this.userData.id,
          })
        }
        this.showLoading = true
        const url = `?limit=10000&filter=` + JSON.stringify(this.arrayFilters)
        const resp = await RestrictionsService.getRestriccionesProv(url, this.$store)
        console.log('GETTING ALL DATA', resp)

        if (resp.status) {
          this.allData = resp.data.rows
          this.records = this.allData
          this.totalElements = resp.data.responseFilter.total_rows
        }
        this.getSortedData('date_identified', 'desc')
        console.log('RECORDS SORTS', this.allDataSorted)
        this.records = this.allDataSorted[0]
        this.showLoading = false
      },
      getAttributeValue(obj, attribute) {
        // Si el atributo contiene un punto, es un atributo anidado
        if (attribute.includes('.')) {
          const parts = attribute.split('.')
          let value = obj

          for (const part of parts) {
            if (value && value.hasOwnProperty(part)) {
              value = value[part]
            } else {
              return null // Manejo de error si no se encuentra el atributo anidado
            }
          }

          return value
        } else {
          return obj[attribute]
        }
      },

      getSortedData(sortBy, sortOrder) {
        let sortedData = [...this.allData]
        if (this.description != null && this.description != '') {
          const searchTerm = this.description.toLowerCase()
          sortedData = sortedData.filter(item => item.description.toLowerCase().includes(searchTerm))
        }
        sortedData.sort((a, b) => {
          const aValue = this.getAttributeValue(a, sortBy)
          const bValue = this.getAttributeValue(b, sortBy)

          if (sortOrder === 'asc') {
            return aValue < bValue ? -1 : aValue > bValue ? 1 : 0
          } else if (sortOrder === 'desc') {
            return aValue > bValue ? -1 : aValue < bValue ? 1 : 0
          }
        })

        this.allDataSorted = []
        for (let i = 0; i < sortedData.length; i += this.showEntrie) {
          this.allDataSorted.push(sortedData.slice(i, i + this.showEntrie))
        }
      },
      showTimeLine(item) {
        console.log('item', item)
        this.restriction_id = item.id
        this.$refs['modal-comment'].show()
        this.getTimeLine(item.id)
        this.itemId = item.id
      },
      edit(item) {
        console.log('item', item)
        // this.$router.push('/provicionales/editar/' + item.id)
        this.form.id = item.id
        this.form.code = item.code
        this.form.description = item.description
        this.form.priority = item.priority
        this.form.category_id = item.category ? item.category.id : null
        this.form.date_identified = item.date_identified
        this.form.date_required = item.date_required
        this.form.leadtime = item.leadtime
        this.form.project_id = this.project_id
        this.form.type = item.type
        console.log('this.form', this.form)
        this.isAdd = true
        this.$refs.provAdd.setData(this.form)
        this.$refs.provAdd.getData(this.project_id)
      },
      async getTimeLine(id) {
        const resp = await RestrictionsService.getComments(id, this.$store)
        console.log('ENTRÓ A TIMELINE')
        console.log('commets', resp)
        if (resp.status) {
          this.timeLine = resp.data
          console.log('ENTRÓ A TIMELINE -this.timeLine', this.timeLine)
        }
      },
      async addComment() {
        this.$refs.addComentarios.validate().then(async success => {
          if (success) {
            this.isDisabled = true
            let datos = {}
            const userData = JSON.parse(localStorage.getItem('userData'))
            datos.restriction_id = this.restriction_id
            datos.description = this.comment
            datos.user_id = userData.id
            console.log('datos', datos)
            const respComment = await RestrictionsService.saveComent(datos, this.$store)
            if (respComment.status) {
              /* this.$swal({
              title: "Registrado",
              text: "El comentario ha sido registrado.",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            }); */
              this.addComent = false
              this.getTimeLine(this.itemId)
              this.getAllData()
              this.closeComment()
              /* this.$refs["modal-comment"].hide(); */
            } else {
              this.$swal({
                title: 'Error!',
                text: ' Hubo un error al registrar el comentario',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
            this.isDisabled = false
          }
        })
      },
      async rejectAction(item) {
        console.log('item', item)
        this.$swal
          .fire({
            title: 'Seguro que desea rechazar esta restricción?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, rechazar',
            html: `
              <input type="text" style="margin: 15px 0" id="inputValueProvisionales" class="swal2-input" placeholder="Ingrese la razón">
            `,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
          .then(async result => {
            if (result.value) {
              const inputValue = document.getElementById('inputValueProvisionales').value

              const resp = await RestrictionsService.saveChangeStatus(
                {
                  status: 'Rechazado',
                  array_id: item.id,
                  reason_reject: inputValue,
                  project_id: this.project_id,
                  user_id: this.userData.id,
                },
                this.$store
              )
              if (resp.status) {
                this.currentPage = 1
                this.$swal({
                  icon: 'success',
                  title: 'Rechazado!',
                  text: 'La restricción fue rechazada con éxito.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getAllData()
              } else {
                this.$swal({
                  icon: 'error',
                  title: 'Error',
                  text: 'Ocurrió un error al liberar la restricción seleccionada.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            }
          })
      },
      async deleteAction(data) {
        this.$swal({
          title: '¿Desea eliminar esta restricción?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, eliminalo',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.deleteData(data.id)
          }
        })
      },
      async deleteData(id) {
        const resp = await RestrictionsService.deleteRestriccion(id, this.$store)
        if (resp.status) {
          this.currentPage = 1
          this.$swal({
            icon: 'success',
            title: 'Eliminado!',
            text: 'La restricción ha sido eliminado.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.getAllData()
        } else {
          this.$swal({
            icon: 'error',
            title: 'Error',
            text: 'Ocurrió un error al eliminar la restricción seleccionada.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .content_main {
    .content_timeline {
      max-height: 450px;
      overflow-y: scroll;
      scroll-behavior: smooth;
    }
    .content_form {
      display: flex;
      justify-content: center;
    }
  }
  .select-obra .vs__dropdown-menu {
    max-height: 200px;
    overflow-y: scroll;
  }
  .column-actions {
    width: 330px;
  }
  .date-class {
    width: 100px;
  }
  // .description-class {
  //   width: 444.04px;
  // }
  .sticky {
    position: sticky;
    z-index: 3;
  }

  .table-overflow {
    overflow-x: auto;
    width: 100%;

    thead {
      display: flex;
      overflow-x: auto;
      position: absolute;
      top: 0;

      &.fixed {
        position: fixed;
        transform: translateX(0px) !important;
        z-index: 2;
      }

      tr {
        display: flex;
        flex: 1 1 auto;

        th {
          flex: 0 0 auto;
          padding: 0.72rem 2rem !important;
        }
      }
    }

    tbody {
      overflow-x: auto;

      tr {
        display: flex;

        &.b-table-empty-row {
          td {
            flex: 0 0 100%;
          }
        }

        td {
          flex: 0 0 auto;
        }
      }
    }
  }
</style>
